import React, { useState, useEffect }  from 'react';
import { BrowserRouter as Router,Routes, Route, useLocation} from 'react-router-dom';
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Program } from "./components/program";
import { Courses } from "./components/courses"
import { Graduates } from './components/graduates';
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const AppLayout = ({ landingPageData }) => (
  <div>
    <Navigation />
    <Header data={landingPageData.Header} />
    <Courses data={landingPageData.Courses} contact={landingPageData.Contact} />
    <Program data={landingPageData.Programs} contact={landingPageData.Contact} />
    <Graduates data={landingPageData.Graduates} contact={landingPageData.Contact} />
    <Features data={landingPageData.Features} />
    <About data={landingPageData.About} />
    <Team data={landingPageData.Team} />
    <Contact data={landingPageData.Contact} />
  </div>
);

const MainRouter = () => {
  const location = useLocation();
  const isApiRoute = location.pathname.startsWith('/api/');
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  function Redirect() {
    // 👇️ redirect to external URL
    window.location.replace('https://interlemd.edu.co');
  
    return null;
  }

  return isApiRoute ? (
    <Routes>
      <Route path="/api/" element={<Redirect />} />
    </Routes>
  ) : (
    <>
      <AppLayout landingPageData={landingPageData} />
    </>
  );
};

const App = () => (
  <Router>
    <MainRouter/>
  </Router>
);

export default App;