
import React from 'react';
import CardMedia from '@mui/material/CardMedia';

export const MediaCardHeader = ({ textImg, smallImage, color }) => {

  const renderCardMedia = () => {
    return <CardMedia
      component="img"
      height="140"
      image={smallImage}
      alt={textImg}
    />
  }
  const renderImageDynamic = () => {
    const color_card_header=color?color:"#535d6c";   
   // return <img src={`data:image/svg+xml;utf8,${image_text}`}/> 
    return <svg
    width="345"
    height="145"
    viewBox="0 0 92.604165 40.224563"
    version="1.1"
    id="svg5"
    xmlns="http://www.w3.org/2000/svg">
   <defs
      id="defs2">
     <meshgradient
        id="meshgradient3412"
        gradientUnits="userSpaceOnUse"
        x="-1.0888236"
        y="9.5377331"
        gradientTransform="translate(1.0888231,-9.9405323)">
       <meshrow
          id="meshrow3414">
         <meshpatch
            id="meshpatch3416">
           <stop
              path="c 30.8681,0  61.7361,0  92.6042,0"
              style={ {"stopColor": "#ffffff", "stopOpacity": 1}}
              id="stop3418" />
           <stop
              path="c 0,13.3187  0,26.6374  0,39.956"
              style= {{"stopColor": "#2d5016","stopOpacity": 1}}
              id="stop3420" />
           <stop
              path="c -30.8681,0  -61.7361,0  -92.6042,0"
              style={{"stopColor": "#ffffff","stopOpacity": 1}}
              id="stop3422" />
           <stop
              path="c 0,-13.3187  0,-26.6374  0,-39.956"
              style={{"stopColor": "#2d5016","stopOpacity": 1}}
              id="stop3424" />
         </meshpatch>
       </meshrow>
     </meshgradient>
   </defs>
   <g
      id="layer1"
      style={{
        "display": "inline",
        "strokeWidth": "0",
        "strokeDasharray": "none"
    }}
      transform="translate(-2.0431315e-6,0.26853332)">
     <rect
        style={{"fill":color_card_header, "stroke":"#ffffff", "strokeWidth":0,"strokeLinejoin":"round","strokeDasharray":"none"}}
        id="rect1160"
        width="92.604164"
        height="39.6875"
        x="2.6457672e-06"
        y="0.26853135" />
   </g>
   <g
      id="layer2"
      style={{
        "fill": "#ffffff",
        "fillOpacity": "1"
    }}
      transform="translate(3.6421715e-7,0.40279972)">
     <rect
        style={{
          "opacity": 0.356383,
          "fill": {"url":'#meshgradient3412'},
          "fillOpacity": 1,
          "stroke": "#ffffff",
          "strokeWidth": 0,
          "strokeLinejoin": "round",
          "strokeDasharray": "none"
      }}
        id="rect298-3"
        width="92.604164"
        height="39.956032"
        x="1.357829e-07"
        y="-0.40279922" />
     <rect
        style={{
          "opacity": 0.351064,
          "fill": "#000000",
          "fillOpacity": 1,
          "stroke": "#ffffff",
          "strokeWidth": 0,
          "strokeLinejoin": "round",
          "strokeDasharray": "none"
      }}
        id="rect298"
        width="92.604164"
        height="14.642264"
        x="2.3841856e-07"
        y="12.656883" />
     <text
        xmlSpace="preserve"
        style={{
          "fontWeight": "bold",
          "fontSize": '6.98892px',
          "fontFamily": "'Book Antiqua'",
          "InkscapeFontSpecification": "'Book Antiqua Bold'",
          "display": "inline",
          "opacity": 1,
          "fill": "#ffffff",
          "fillOpacity": 1,
          "stroke": "#ffffff",
          "strokeWidth": 0,
          "strokeLinejoin": "round",
          "strokeDasharray": "none"
      }}
        x="0"
        y="22.196709"
        id="text2318"><tspan
          id="tspan2316"
          style={{"fill":"#ffffff","fillOpacity":1,"strokeWidth":0,"strokeDasharray":"none"}}
          x="0"
          y="22.196709">{textImg}</tspan></text>
   </g>
 <script id="mesh_polyfill" type="text/javascript"></script>
 </svg>
  }
  return (<div>
    {smallImage? renderCardMedia(): renderImageDynamic()}
  </div>
  );
}