
import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Dialog } from 'primereact/dialog';
import { CourseDetail } from "./course-detail";
import { MediaCardHeader } from "./media-card-header";


 
//import { Button } from 'primereact/button';

export const  MediaCard = ({ contact, title, 
  summary, smallImage, html, 
  buy_url, category,  
  colorBgCardHeader, 
  topics_titles,
  description,
  goals,
  textImg
}) =>  {

  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayInfoDetail, setDisplayInfoDetail] = useState(false);
  const [ setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
        'displayInfoDetail': setDisplayInfoDetail
    }

    const onClick = (name, position) => {
      dialogFuncMap[`${name}`](true);

      if (position) {
          setPosition(position);
        }
    }

    const onHide = (name) => {
      dialogFuncMap[`${name}`](false);
    }

    const renderElement=(category )=>{ 
      const contentDetail = {
        "topics_titles": topics_titles,
        "description": description,
        "goals": goals,
        "title":title,
        "summary":summary,
        "buy_url":buy_url
      }    
      if(category && category === "CURSO")
         return  (<div>
          <Dialog header="Descripción" visible={displayInfoDetail} style={{ width: '50vw' }} onHide={() => onHide('displayInfoDetail')}
            breakpoints={{'960px': '75vw', '640px': '100vw'}}>               
            {html?<div id='prog_pop_content_id'>
              <div dangerouslySetInnerHTML={ { __html: html } }></div>
            </div>:<CourseDetail props={contentDetail}/>   
            }      
          </Dialog>
          <Button variant="outlined" size="small" onClick={() => onClick('displayInfoDetail')}  >Ver Mas</Button>
      </div>);
      return (<div>
        <Dialog header="Descripción" visible={displayBasic} style={{ width: '50vw' }} onHide={() => onHide('displayBasic')}
          breakpoints={{'960px': '75vw', '640px': '100vw'}}>
            <div id='prog_pop_content_id'>
              <div dangerouslySetInnerHTML={ { __html: html } }></div>
            </div>                            
        </Dialog>
        <Button variant="outlined" size="small" onClick={() => onClick('displayBasic')}  >Ver Mas</Button>
    </div>);
   }

  return (
  <div className='portfolio-item'>
    <div className='hover-bg'>
    <Card sx={{ maxWidth: 345, minHeight:320 }}>
      <MediaCardHeader textImg={textImg}  smallImage={smallImage} color={colorBgCardHeader}/>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        {title}
        </Typography>
        <Typography variant="h6" color="text.secondary">
        {summary}
        </Typography>
      </CardContent>
      <CardActions>
        {renderElement(category)}
        {buy_url?<div>
                <Button  target="_blank" variant="contained" color="success" size="small" href={buy_url} >Comprar</Button>
            </div>:
        ''}
        
       {contact?
        <div className='social'>
                <ul>
                  {contact.whatsapp?<li>
                    <a href={contact.whatsapp ? contact.whatsapp : '/'} target='_blank' rel="noopener noreferrer">
                      <i className='fa fa-whatsapp'></i>
                    </a>
                  </li>:''}
                  
                </ul>
        </div>:
        ''}        
      </CardActions>
    </Card>
    </div>
  </div>    
  );
}