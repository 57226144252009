import Button from '@mui/material/Button';
export const CourseDetail = ({ props }) => {

    return (
        <div id='course-detail' className='text-center'   >
            <div className='container col-sm-12'>
                <div className="row">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <p><img alt="" className="img-responsive pull-left" height="133" src="img/certificate-course-sign.svg" width="150" /></p>


                            <p>¡Aprovecha esta gran oportunidad para impulsar tu crecimiento personal y profesional con nuestros cursos online!</p>
                            <p>¿Quieres avanzar en tu carrera, adquirir nuevas habilidades o explorar áreas de interés? ¡Estás en el lugar indicado!  Nuestra plataforma de cursos está diseñada para ayudarte a alcanzar tus metas.</p>

                            <p>&nbsp;</p>
                            <h3><span><strong>{props.title}</strong></span></h3>

                            <h3><span><strong>¡CERTIFICATE!</strong></span></h3>
                            <Button target="_blank" variant="contained" color="success" size="large" href={props.buy_url} >Comprar</Button>
                            <p></p>
                            <p>
                            Una vez finalice el proceso de pago en línea, llegará a tu correo el comprobante de pago de tu compra, luego te enviaremos los datos e indicaciones para acceder a la plataforma. Cualquier duda o inquietud puedes llamar;
                             {' '} <span>
                                    <i className='fa fa-phone'></i>
                                </span>{' '}
                                3142981372 - 3147616418 .
                            </p>
                            <p></p>
                            <p>
                            Al realizar tu compra aceptas nuestra política de uso y tratamiento de datos (<a href='https://itinterlemd.github.io/pages/terminos' target="_blank" rel="noopener noreferrer">Ver</a>).
                            </p>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <h3 className="sub-title">Información del curso</h3>
                            <div className="course-information read-more-area" data-readmore="" aria-expanded="true" id="rmjs-1">
                                <div className="topics">
                                    <h4 className="title-info">
                                        <em className="fa fa-book"></em> {props.title}
                                    </h4>
                                    <div className="content-info">
                                        <h3 className="ck ck-title2"><strong>Objetivos</strong></h3>
                                        <div dangerouslySetInnerHTML={{ __html: props.goals }}></div>
                                    </div>
                                    <div className="content-info">
                                        <h3 className="ck ck-title2"><strong>Contenido</strong></h3>
                                        <div dangerouslySetInnerHTML={{ __html: props.topics_titles }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}



