//import { Image } from "./image";
import { CuorsesHeader } from "./courses-header";
import { MediaCard } from "./media-card";

export const  Courses = (props) => { 

  return (
    <div id='courses' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Cursos Certificados</h2> 
                
        </div>
        <div className='row'>
        <CuorsesHeader/>  
        </div>
        <div className='row'>
          <div className='course-items'>        

            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4'>
                  <MediaCard contact={props.contact}  title={d.title}  smallImage={d.smallImage} 
                  summary={d.summary} html={d.html} buy_url={d.buy_url} category={d.category}
                  colorBgCardHeader={d.colorBgCardHeader}
                  topics_titles={d.topics_titles}
                  description={d.description}
                  goals={d.goals}
                  textImg={d.textImg}/>
                </div>
              ))
              : 'Loading...'}
          </div>
        </div>
      </div>
    </div>
  )
}
