import React from 'react'

export const CuorsesHeader = (props) => {

  return (
    <div>
      <div id='courses-header'>
        <div className='container'>
          <div className='col-md-12'>
            <div className='row'>
                <div className='section-sumary'>
                  <p>
                    ¡Aprovecha al máximo tu tiempo y potencia tus habilidades desde la comodidad de tu hogar con nuestros cursos virtuales!                                  
                  </p>
                  <p>
                  Tenemos todo lo que necesitas para impulsar tu carrera y alcanzar tus metas, nuestros cursos virtuales ofrecen flexibilidad total: estudia a tu propio ritmo, accede a los materiales del curso en cualquier momento y lugar.                                  
                  </p>
                  <p>
                  ¡Inscríbete ahora y comienza tu aprendizaje!
                  </p>
                </div>
            </div>
          </div>   
          
        </div>
      </div>
    </div>
  )
}
